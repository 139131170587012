import QRCode from "qrcode.react";
import { Helmet } from "react-helmet";
import React, { useState, useEffect } from "react";
import { ThemeToggler } from "gatsby-plugin-dark-mode";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMoon,
} from "@fortawesome/free-solid-svg-icons";
import {
  faWhatsapp,
  faFacebook,
  faTwitter,
  faLinkedin
} from "@fortawesome/free-brands-svg-icons";

import "../styles/upi.scss";

const UpiPage = ({ location }) => {
  const [l, setL] = useState("");
  const [n, setN] = useState("");
  const [vpa, setVpa] = useState("");
  const [uri, setUri] = useState("");
  const [copied, setCopied] = useState("");
  const [shared, setShared] = useState("");

  useEffect(() => {
    const params = new URLSearchParams(location.search);

    setVpa(params.get("vpa"));
    setN(params.get("name"));

    if (vpa && n) {
      let org = Math.floor(Math.random() * 1e5) + 1;

      if (!uri) {
        let link;
        const iOS =
          /iPad|iPhone|iPod/.test(navigator.userAgent) &&
          !window.MSStream;

        if (iOS) {
          link = `gpay://upi/pay?pa=${vpa}&pn=${n}&mc=0000&mode=02&purpose=00&orgid=${org}`;
        } else {
          link = `upi://pay?pa=${vpa}&pn=${n}&mc=0000&mode=02&purpose=00&orgid=${org}`;
        }

        setUri(encodeURI(link));
        setTimeout(() => {
          document.querySelector('.link-actual').click();
        }, 3e3);
      }
    }

  }, [vpa]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const v = document.querySelector("input[name=\"vpa\"]").value;
    const na = document.querySelector("input[name=\"name\"]").value;
    const li = location.href.split('?')[0] + `?vpa=${v}&name=${encodeURI(na)}`;
    fetch(`https://is.gd/create.php?format=json&url=${encodeURIComponent(li.replace('localhost:8000', 'ghosh.pro'))}`)
      .then((d) => d.json())
      .then((_d) => setL(_d.shorturl))
      .catch((e) => setL(li));
  };

  return (
    <>
      <Helmet>
        <title>{uri ? `Pay ${n}` : "UPI Magic Link"}</title>
      </Helmet>
      <main className="upi-container">
        <h1>UPI Magic Link</h1>

        {!uri && (!vpa || !n) && (
          <div className="form-upi">
            <form className="form" onSubmit={handleSubmit}>
              <input type="text" name="vpa" placeholder="UPI ID (example@sbi)" required />
              <input type="text" name="name" placeholder="Display Name" required />
              <input type="submit" value="generate link" />

              {l && (
                <>
                  <input type="button" value={copied || "copy link"} onClick={(e) => {
                    e.target.focus();
                    if (navigator) {
                      try {
                        navigator.clipboard.writeText(l);
                        setCopied("copied!");
                        setTimeout(async () => {
                          setCopied("");
                        }, 5e3);
                      } catch (err) {
                        setCopied("could not copy :(");
                        setTimeout(() => {
                          setCopied("");
                        }, 5e3);
                      }
                    }
                  }} />

                  {navigator && navigator.share && (
                    <input type="button" value={shared || "share link"} onClick={(e) => {
                      e.target.focus();
                      if (navigator.share)
                        navigator
                          .share({
                            url: l,
                          })
                          .then((e) => {
                            setShared("shared!");
                            setTimeout(() => {
                              setShared("");
                            }, 5e3);
                          })
                          .catch((e) => {
                            setShared("could not share :(");
                            setTimeout(() => {
                              setShared("");
                            }, 5e3);
                          });
                    }} />
                  )}

                  {!navigator.share && (
                    <div className="sharing-buttons">
                      <a
                        href={"https://wa.me/?text=" + encodeURIComponent("Click to pay: " + l)}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FontAwesomeIcon
                          icon={faWhatsapp}
                          size="2x"
                        />
                      </a>
                      <a
                        href={"https://www.facebook.com/share.php?u=" + encodeURIComponent(l)}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FontAwesomeIcon
                          icon={faFacebook}
                          size="2x"
                        />
                      </a>
                      <a
                        href={"https://www.twitter.com/share?url=" + encodeURIComponent(l)}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FontAwesomeIcon
                          icon={faTwitter}
                          size="2x"
                        />
                      </a>
                      <a
                        href={"https://www.linkedin.com/shareArticle?mini=true&url=" + encodeURIComponent(l)}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FontAwesomeIcon
                          icon={faLinkedin}
                          size="2x"
                        />
                      </a>
                    </div>
                  )}
                </>
              )}
            </form>
          </div>
        )}

        {uri && (
          <>
            <div className="qr">
              {uri && <QRCode
                value={uri}
                renderAs="svg"
                level="H"
                size={200}
              />}
            </div>
            <p><strong>UPI ID: </strong> {vpa}</p>
            <a style={{ hidden: true }} className="link-actual" target="_parent" href={uri} />
            <p className="note">if upi app is not opening, you can scan to pay or use the upi id</p>
          </>
        )}
      </main>

      <ThemeToggler>
        {({ theme, toggleTheme }) => {
          return (
            <div className="toggler">
              <FontAwesomeIcon
                icon={faMoon}
                onClick={e => toggleTheme(theme === "dark" ? "light" : "dark")}
                onKeyDown={e => toggleTheme(theme === "dark" ? "light" : "dark")}
              />
            </div>
          );
        }}
      </ThemeToggler>
    </>
  );
};

export default UpiPage;
